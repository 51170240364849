import {Kebab24} from "@bphxd/ds-core-react/lib/icons";
import PropTypes from "prop-types";
import {useCallback, useMemo, useState} from "react";
import {useNavigate, useParams} from "react-router-dom";
import {
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  UncontrolledDropdown,
} from "reactstrap";

import {DIV_CODE_GF, DIV_CODE_SAF} from "constants/divisionDetails";
import {actionConfig, linkAction} from "../utils";
import ModalToShow from "./ModalToShow";

import {actionItems, status} from "../constants";

const ActionDropdown = ({
  row,
  table,
  navigateToAllocation,
  currentFilterValues,
  periodStatus,
}) => {
  const [actionModalVisible, setActionModalVisible] = useState("");
  const {division, country} = useParams();
  const divisionCode = division.toUpperCase();
  const linkError = row?.original.outboundRecords?.linkError;
  const rowStatus = row?.original.outboundRecords?.status;
  const fromMbLocationGroupId =
    row?.original.inboundRecord?.fromMbLocationGroupId ?? null;
  const recordStatus = row?.original.inboundRecord?.recordStatus;

  let finalRowStatus = rowStatus;

  if (
    linkError &&
    (linkError === "PURCHASE" || linkError === "PURCHASE_SALES") &&
    rowStatus === status.AVAILABLE
  ) {
    finalRowStatus = status.INVALID_PURCHASE;
  } else if (
    linkError &&
    ["PURCHASE_SALES", "SALES"].includes(linkError) &&
    [status.ALLOCATED, status.ALLOCATED_AND_SENT].includes(rowStatus) &&
    divisionCode === DIV_CODE_SAF
  ) {
    finalRowStatus = status.INVALID_OUTGOING;
  } else if (
    finalRowStatus === status.AVAILABLE &&
    fromMbLocationGroupId !== null
  ) {
    finalRowStatus = status.AVAILABLE_FROM_TRANSFER;
  }
  const isTransfer = useMemo(() => {
    return rowStatus === status.AVAILABLE && fromMbLocationGroupId !== null;
  }, [fromMbLocationGroupId, rowStatus]);

  const isDocumentGenerated = useMemo(() => {
    return (
      row?.original?.outboundRecords?.document !== null &&
      divisionCode !== DIV_CODE_GF
    );
  }, [divisionCode, row?.original?.outboundRecords?.document]);

  const isMbClosed = useMemo(() => {
    return periodStatus === "CLOSED" || recordStatus === "CLOSED";
  }, [periodStatus, recordStatus]);

  if (isTransfer) {
    finalRowStatus = status.AVAILABLE_FROM_TRANSFER;
  }

  if (isDocumentGenerated && finalRowStatus !== status.INVALID_OUTGOING) {
    finalRowStatus =
      rowStatus === status.TRANSFERRED ||
      rowStatus === status.TRANSFERRED_AND_SENT
        ? status.TRANSFERRED_AND_SENT
        : status.ALLOCATED_AND_SENT;
  }

  if (isMbClosed) {
    finalRowStatus = status.CARRIED_OVER;
  }

  const navigate = useNavigate();

  const menuItemToBeDisabled =
    linkError &&
    finalRowStatus !== status.INVALID_OUTGOING &&
    finalRowStatus !== status.INVALID_PURCHASE;

  const availableActions = useMemo(
    () => [
      actionItems.VIEW_DETAILS,
      actionItems.REMOVE_ALLOCATION,
      actionItems.REMOVE_DOCUMENT,
      actionItems.REMOVE_FROM_BALANCE,
      actionItems.REMOVE_RESERVATION,
      actionItems.REMOVE_WRITE_OFF,
      actionItems.REMOVE_TRANSFER,
      actionItems.UNRETIRE_FOR_MANDATE,
    ],
    [],
  );

  const menuItemToBeDisabledForCoproTepsa = useCallback(
    (action) => {
      if (
        row?.original?.outboundRecords?.status === status.AVAILABLE &&
        action === actionItems.RETIRE_FOR_MANDATE_SNS &&
        currentFilterValues?.mbLocationGroupName === "Tepsa Tarragona"
      ) {
        return true;
      }
      return false;
    },
    [row, currentFilterValues?.mbLocationGroupName],
  );

  const ACTIONS = useMemo(
    () =>
      Object.values(actionItems).map((action) => ({
        name: action,
        isVisible:
          actionConfig[finalRowStatus + divisionCode]?.visible.includes(action),
        isDisabled:
          !actionConfig[finalRowStatus + divisionCode]?.enabled.includes(
            action,
          ) ||
          (menuItemToBeDisabled && !availableActions.includes(action)) ||
          menuItemToBeDisabledForCoproTepsa(action),
        action: () =>
          linkAction(
            divisionCode,
            setActionModalVisible,
            action,
            row,
            table,
            navigate,
            navigateToAllocation,
            country,
          ),
      })),
    [
      finalRowStatus,
      divisionCode,
      menuItemToBeDisabled,
      menuItemToBeDisabledForCoproTepsa,
      availableActions,
      row,
      table,
      navigate,
      navigateToAllocation,
      country,
    ],
  );

  return (
    <>
      <UncontrolledDropdown direction="start">
        <DropdownToggle
          color="standard-quartenary"
          className="!px-0"
          caret
          onClick={() => setActionModalVisible("")}
        >
          <Kebab24 />
        </DropdownToggle>
        <DropdownMenu container="body">
          {ACTIONS.filter((action) => action.isVisible).map((action) => (
            <DropdownItem
              key={action.name}
              onClick={action.action}
              disabled={action.isDisabled}
              data-test={`data-test-mb-table-action-${action.name}`}
            >
              {action.name}
            </DropdownItem>
          ))}
        </DropdownMenu>
      </UncontrolledDropdown>
      {actionModalVisible && (
        <ModalToShow
          modal={actionModalVisible}
          setModal={setActionModalVisible}
          rowData={row}
          divisionCode={divisionCode}
          currentFilterValues={currentFilterValues}
        />
      )}
    </>
  );
};

ActionDropdown.propTypes = {
  row: PropTypes.object,
  table: PropTypes.object,
  navigateToAllocation: PropTypes.func,
  currentFilterValues: PropTypes.object,
  periodStatus: PropTypes.string,
};

export default ActionDropdown;
