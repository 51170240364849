import {FilePdf24, Info24} from "@bphxd/ds-core-react/lib/icons";
import outboundTypes, {ALL_MANDATES} from "constants/allocation";
import {NUMBER_OF_DECIMALS} from "constants/common";
import ColumnHighlight from "modules/GlobalMassBalance/components/ColumnHighlight";
import {getFilterValue} from "modules/GlobalMassBalance/utils";
import moment from "moment";
import {Link} from "react-router-dom";
import {UncontrolledTooltip} from "reactstrap";
import {
  formatNumberIfExists,
  getFormattedNumberWithCheck,
} from "utils/numberUtil";

function parseDeliveryDate(deliveryDate) {
  const dateArr = deliveryDate?.split("-");
  const year = dateArr[0];
  const month = dateArr[1];
  const day = 1;
  const date = new Date(year, month - 1, day);
  return date;
}

export const INCOMING_COLUMNS_SAF = (
  dateFormat,
  decimalFormat,
  country,
  siteReferenceId,
  divisionId,
) => [
  {
    header: "Incoming",
    columns: [
      {
        header: "Physical receipt date",
        accessorKey: "physicalReceiptDate",
        key: "physicalReceiptDate",
        visible: true,
        meta: {
          filterVariant: "daterange",
        },
        filterFn: "dateRangeFilterFn",
        cell: ({cell}) => {
          return cell.getValue() && moment(cell.getValue()).format(dateFormat);
        },
      },
      {
        header: "Document",
        accessorKey: "incomingDocument",
        key: "incomingDocument",
        visible: true,
        cell: ({cell, row}) => {
          const tooltipId = `incomingDocument${row?.id}`;
          return (
            <>
              <Link
                to={{
                  pathname: `/document-manager/incoming/${country}/detailedView/${encodeURIComponent(
                    row?.original?.certificateInboundEuId,
                  )}`,
                  search: `?siteReferenceId=${siteReferenceId}&divisionId=${divisionId}`,
                }}
                className="link-dark"
                id={tooltipId}
              >
                <u className="flex">
                  {cell.getValue() && <FilePdf24 />}
                  <ColumnHighlight
                    value={cell.getValue()}
                    columnFilter={getFilterValue(cell, "incomingDocument")}
                    hasEllipsis
                    isCertificate
                  />
                </u>
              </Link>
              <UncontrolledTooltip placement="bottom" target={tooltipId}>
                {cell.getValue() ?? ""}
              </UncontrolledTooltip>
            </>
          );
        },
      },
      {
        header: "Raw material",
        accessorKey: "rawMaterial",
        key: "rawMaterial",
        visible: true,
        meta: {
          filterVariant: "select",
        },
      },
      {
        header: "Country of origin",
        accessorKey: "countryOfOrigin",
        key: "countryOfOrigin",
        visible: true,
        meta: {
          filterVariant: "select",
        },
      },
      {
        header: "GHG total gCO2eq/MJ",
        accessorKey: "ghgTotalMj",
        key: "ghgTotalMj",
        visible: true,
        meta: {
          filterVariant: "range",
        },
        dataType: "number",
        accessorFn: (row) => {
          return row?.ghgTotalMj;
        },
        cell: ({cell}) => {
          return getFormattedNumberWithCheck(
            cell.getValue(),
            NUMBER_OF_DECIMALS,
          );
        },
      },
      {
        header: "Sustainable quantity m³",
        accessorKey: "sustainableQuantityM3",
        key: "sustainableQuantityM3",
        visible: true,
        disabled: true,
        dataType: "number",
        cell: ({cell}) => {
          return getFormattedNumberWithCheck(
            cell.getValue(),
            NUMBER_OF_DECIMALS,
          );
        },
        filterFn: "numberFilterFn",
      },
      {
        header: "Issuance date",
        accessorKey: "issuanceDate",
        key: "issuanceDate",
        visible: false,
        meta: {
          filterVariant: "daterange",
        },
        filterFn: "dateRangeFilterFn",
        cell: ({cell}) => {
          return cell.getValue() && moment(cell.getValue()).format(dateFormat);
        },
      },
      {
        header: "Quantity m³",
        accessorKey: "productQtyM3",
        key: "productQtyM3",
        visible: false,
        meta: {
          filterVariant: "range",
        },
        dataType: "number",
        accessorFn: (row) => {
          return row?.productQtyM3;
        },
        cell: ({cell}) => {
          return getFormattedNumberWithCheck(
            cell.getValue(),
            NUMBER_OF_DECIMALS,
          );
        },
      },
      {
        header: "Quantity MT",
        accessorKey: "productQtyMt",
        key: "productQtyMt",
        visible: false,
        meta: {
          filterVariant: "range",
        },
        dataType: "number",
        accessorFn: (row) => {
          return row?.productQtyMt;
        },
        cell: ({cell}) => {
          return getFormattedNumberWithCheck(
            cell.getValue(),
            NUMBER_OF_DECIMALS,
          );
        },
      },
      {
        header: "Supplier",
        accessorKey: "supplier",
        key: "supplier",
        visible: false,
        meta: {
          filterVariant: "select",
        },
      },
      {
        header: "Transport system",
        accessorKey: "transportSystem",
        key: "transportSystem",
        visible: false,
        meta: {
          filterVariant: "select",
        },
      },
      {
        header: "Vessel name",
        accessorKey: "vesselName",
        key: "vesselName",
        visible: false,
        meta: {
          filterVariant: "select",
        },
      },
      {
        header: "Transport loss/gain",
        accessorKey: "transportLossGain",
        key: "transportLossGain",
        visible: false,
        meta: {
          filterVariant: "search",
        },
      },
      {
        header: "Certified",
        accessorKey: "certified",
        key: "certified",
        visible: false,
        meta: {
          filterVariant: "select",
        },
      },
      {
        header: "Received quantity MT",
        accessorKey: "receivedQuantityMT",
        key: "receivedQuantityMT",
        visible: false,
        filterFn: "numberFilterFn",
        dataType: "number",
        cell: ({cell}) => {
          return getFormattedNumberWithCheck(
            cell.getValue(),
            NUMBER_OF_DECIMALS,
          );
        },
      },
      {
        header: "Sustainable quantity MT",
        accessorKey: "sustainableQuantityMT",
        key: "sustainableQuantityMT",
        visible: false,
        filterFn: "numberFilterFn",
        dataType: "number",
        cell: ({cell}) => {
          return getFormattedNumberWithCheck(
            cell.getValue(),
            NUMBER_OF_DECIMALS,
          );
        },
      },
      {
        header: "GHG savings %",
        accessorKey: "ghgSavings",
        key: "ghgSavings",
        visible: false,
        meta: {
          filterVariant: "range",
        },
        dataType: "number",
        accessorFn: (row) => {
          return row?.ghgEmissionSaving;
        },
        cell: ({cell}) => {
          return getFormattedNumberWithCheck(
            cell.getValue(),
            NUMBER_OF_DECIMALS,
          );
        },
      },
    ],
  },
];

export const OUTGOING_COLUMNS_SAF = (dateFormat, decimalFormat, country) => [
  {
    header: "Outgoing",
    columns: [
      {
        header: "Dispatch date",
        accessorKey: "deliveryDate",
        key: "deliveryDate",
        visible: true,
        meta: {
          filterVariant: "daterange",
        },
        filterFn: "dateRangeFilterFn",
        accessorFn: (row) => {
          return parseDeliveryDate(row?.deliveryDate);
        },
        cell: ({cell}) => {
          const month = moment(cell.getValue()).format("MMMM");
          const year = moment(cell.getValue()).format("YYYY");
          return cell.getValue() && `${month} ${year}`;
        },
      },
      {
        header: "Outbound type",
        accessorKey: "outboundType",
        key: "outboundType",
        visible: true,
        meta: {
          filterVariant: "select",
        },
      },
      {
        header: "Recipient",
        accessorKey: "recipient",
        key: "recipient",
        visible: true,
        meta: {
          filterVariant: "select",
        },
        cell: ({row}) => {
          const tooltipId = `recipient${row?.id}`;
          const sustainableQtyM3 = row?.original?.sustainableQtyM3;
          const recipient = row?.original?.recipient;
          const outboundType = row?.original?.outboundType;
          const isMandate = ALL_MANDATES.includes(outboundType);
          const today = moment(); // Get today's date
          const month = today.format("MMMM");
          const year = today.format("YYYY");
          const displayMonth =
            month === "January"
              ? month
              : today.subtract(1, "month").format("MMMM");
          return (
            <>
              <div id={tooltipId} className="flex">
                {recipient}
                {isMandate && <Info24 className="ml-[5px] text-black" />}
              </div>
              {isMandate && (
                <UncontrolledTooltip
                  placement="bottom"
                  target={tooltipId}
                  style={{borderRadius: "5px"}}
                >
                  <div className="text-[14px] mt-[10px] mb-[12px] text-left">
                    {recipient + " obligation"}
                  </div>
                  <div className="text-[13px] text-[#ffffffa3] text-left">
                    Accrued total remaining to {displayMonth} {year} is{" "}
                    {sustainableQtyM3} m3
                  </div>
                </UncontrolledTooltip>
              )}
            </>
          );
        },
      },
      {
        header: "Ship to location",
        accessorKey: "shipToLocation",
        key: "shipToLocation",
        visible: true,
        meta: {
          filterVariant: "select",
        },
      },
      {
        header: "Sustainable quantity m³",
        accessorKey: "sustainableQtyM3",
        key: "sustainableQtyM3",
        visible: true,
        disabled: true,
        filterFn: "numberFilterFn",
        dataType: "number",
        cell: ({cell, row}) => {
          const outboundType = row?.original?.outboundType;
          return outboundType === outboundTypes.VOLUNTARY
            ? getFormattedNumberWithCheck(cell.getValue(), NUMBER_OF_DECIMALS)
            : "";
        },
      },
      {
        header: "Fuel quantity m³",
        accessorKey: "fuelQtyM3",
        key: "fuelQtyM3",
        visible: true,
        disabled: true,
        filterFn: "numberFilterFn",
        dataType: "number",
        cell: ({cell}) => {
          return getFormattedNumberWithCheck(
            cell.getValue(),
            NUMBER_OF_DECIMALS,
          );
        },
      },

      {
        header: "Sustainable quantity MT",
        accessorKey: "sustainableQuantityMTOutgoing",
        key: "sustainableQuantityMTOutgoing",
        visible: false,
        filterFn: "numberFilterFn",
        dataType: "number",
        cell: ({cell}) => {
          return getFormattedNumberWithCheck(
            cell.getValue(),
            NUMBER_OF_DECIMALS,
          );
        },
      },
      {
        header: "Fuel quantity MT",
        accessorKey: "fuelQuantityMT",
        key: "fuelQuantityMT",
        visible: false,
        filterFn: "numberFilterFn",
        dataType: "number",
        cell: ({cell}) => {
          return getFormattedNumberWithCheck(
            cell.getValue(),
            NUMBER_OF_DECIMALS,
          );
        },
      },
      {
        header: "Product",
        accessorKey: "productOutgoing",
        key: "productOutgoing",
        visible: false,
        meta: {
          filterVariant: "select",
        },
      },
      {
        header: "Transport system",
        accessorKey: "transportSystemOutgoing",
        key: "transportSystemOutgoing",
        visible: false,
        meta: {
          filterVariant: "select",
        },
      },
      {
        header: "Vessel name",
        accessorKey: "vesselNameOutgoing",
        key: "vesselNameOutgoing",
        visible: false,
        meta: {
          filterVariant: "select",
        },
      },
      {
        header: "GHG total gCO2eq/MJ",
        accessorKey: "ghgTotalMjOutgoing",
        key: "ghgTotalMjOutgoing",
        visible: false,
        meta: {
          filterVariant: "range",
        },
        dataType: "number",
        accessorFn: (row) => {
          return row?.ghgTotalMjOutgoing;
        },
        cell: ({cell}) => {
          return getFormattedNumberWithCheck(
            cell.getValue(),
            NUMBER_OF_DECIMALS,
          );
        },
      },
      {
        header: "GHG savings %",
        accessorKey: "ghgSavingsPercentOutgoing",
        key: "ghgSavingsPercentOutgoing",
        visible: false,
        meta: {
          filterVariant: "range",
        },
        dataType: "number",
        accessorFn: (row) => {
          return parseFloat(row?.ghgSavingsPercentOutgoing);
        },
        cell: ({cell}) => {
          return (
            cell.getValue() &&
            formatNumberIfExists(cell.getValue(), decimalFormat, 3)
          );
        },
      },
    ],
  },
];

export const getColumnsSAF = (
  dateFormat,
  decimalFormat,
  country,
  siteReferenceId,
  divisionId,
) => {
  return {
    INCOMING_COLUMNS: INCOMING_COLUMNS_SAF(
      dateFormat,
      decimalFormat,
      country,
      siteReferenceId,
      divisionId,
    ),
    OUTGOING_COLUMNS: OUTGOING_COLUMNS_SAF(
      dateFormat,
      decimalFormat,
      country,
      siteReferenceId,
      divisionId,
    ),
  };
};

export const getColumnsGF = (dateFormat, decimalFormat, country) => {
  return {
    // INCOMING_COLUMNS: INCOMING_COLUMNS_TRS_UK(dateFormat, decimalFormat, country),
    // OUTGOING_COLUMNS: OUTGOING_COLUMNS_TRS_UK(dateFormat, decimalFormat, country),
  };
};
export const getColumnsCPP = (dateFormat, decimalFormat, country) => {
  return {
    // INCOMING_COLUMNS: INCOMING_COLUMNS_TRS_UK(dateFormat, decimalFormat, country),
    // OUTGOING_COLUMNS: OUTGOING_COLUMNS_TRS_UK(dateFormat, decimalFormat, country),
  };
};
