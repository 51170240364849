import PropTypes from "prop-types";
import {Controller} from "react-hook-form";
import {FormFeedback, FormGroup, Input, Label} from "reactstrap";

const UnitOfMeasure = ({control, name, error, rules, disabled = false}) => {
  return (
    <Controller
      rules={rules}
      render={({field: {onChange, value, ref}}) => {
        const mt = {checked: value.toUpperCase() === "MT"};
        const m3 = {checked: value.toUpperCase() === "M3"};
        return (
          <>
            <div>
              <FormGroup
                check
                inline
                className="form-check-alt form-check-lg !mb-0"
              >
                <Input
                  id={`${name}M3`}
                  data-test={`${name}M3`}
                  type="checkbox"
                  invalid={!!error && !disabled}
                  disabled={disabled}
                  onChange={(e) => onChange(e.target.checked ? "M3" : "")}
                  {...m3}
                />
                <Label check for={`${name}M3`}>
                  m<sup>3</sup>
                </Label>
              </FormGroup>
              <FormGroup
                check
                inline
                className="form-check-alt form-check-lg !mb-0"
              >
                <Input
                  innerRef={ref}
                  id={`${name}MT`}
                  type="checkbox"
                  data-test={`${name}MT`}
                  invalid={!!error && !disabled}
                  disabled={disabled}
                  onChange={(e) => onChange(e.target.checked ? "MT" : "")}
                  {...mt}
                />
                <Label check for={`${name}MT`}>
                  Metric tons
                </Label>
              </FormGroup>
            </div>
            {error && !disabled && (
              <FormFeedback style={{display: "block"}}>
                {error.message}
              </FormFeedback>
            )}
          </>
        );
      }}
      control={control}
      name={name}
      defaultValue=""
    />
  );
};

UnitOfMeasure.propTypes = {
  control: PropTypes.object,
  name: PropTypes.string.isRequired,
  error: PropTypes.object,
  rules: PropTypes.object,
  disabled: PropTypes.bool,
};
export default UnitOfMeasure;
