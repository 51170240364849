const FEATURE_FLAGS = {
  DOC_MANAGER_1_4: "doc_man_1_4",
  DOC_MANAGER_SPLITS_PERCENTAGE: "doc_man_splits_percentage",
  COMMON_IS_SUPER_USER: "common_is_super_user",
  CONFIG_CENTRE_SHOW_GLOBAL_CONFIG: "config_centre_show_global_config",
  COMP_MNTR_STRO200_dq: "comp_mntr_stro200_dq",
  COPRO_GMB_ACT: "copro_gmb_act",
  SPAIN_COMPLIANCE: "spain_compliance",
  DOC_MAN_DE_SAF_CHANGES: "doc_man_de_saf_changes",
};

export default FEATURE_FLAGS;
