import {
  COUNTRY_GERMANY,
  COUNTRY_POLAND,
  COUNTRY_SPAIN,
} from "constants/countryDetails";
import {
  DIV_CODE_COPRO,
  DIV_CODE_GF,
  DIV_CODE_SAF,
} from "constants/divisionDetails";

export const getCertificationSystemListByCountryAndDivision = (
  country,
  division,
) => {
  switch (country.toLowerCase()) {
    case COUNTRY_SPAIN:
      switch (division) {
        case DIV_CODE_GF:
          return [
            "2BSvs",
            "2BSvs astra",
            "SNVS Cepsa",
            "SNVS Vertex",
            "2BSvs Axoil",
            "ISCC Cepsa",
            "ISCC EU",
            "ISCC EU Vertex",
          ];
        case DIV_CODE_COPRO:
        default:
          return ["ISCC EU"];
      }

    case COUNTRY_GERMANY:
    case COUNTRY_POLAND:
      return ["Red Cert", "KZR INiG", "ISCC"];

    default:
      return ["ISCC EU"];
  }
};

const productQtyKey = "productQty";
const feedstockQtyKey = "feedstockQty";
const productQtyUomKey = "productQtyUom";
const feedstockQtyUomKey = "feedstockQtyUom";

const getQuantityKeyOrUomKeyByCountryAndDivision = (
  country,
  division,
  getQuantityKey = true,
) => {
  switch (country.toLowerCase()) {
    case COUNTRY_SPAIN:
      switch (division) {
        case DIV_CODE_GF:
          return getQuantityKey ? productQtyKey : productQtyUomKey;
        case DIV_CODE_COPRO:
        default:
          return getQuantityKey ? feedstockQtyKey : feedstockQtyUomKey;
      }
    case COUNTRY_GERMANY:
      switch (division) {
        case DIV_CODE_SAF:
        case DIV_CODE_COPRO:
        default:
          return getQuantityKey ? feedstockQtyKey : feedstockQtyUomKey;
      }
    default:
      switch (division) {
        case DIV_CODE_SAF:
          return getQuantityKey ? productQtyKey : productQtyUomKey;
        default:
          return getQuantityKey ? feedstockQtyKey : feedstockQtyUomKey;
      }
  }
};

export const getQuantityKeyByCountryAndDivision = (country, division) => {
  return getQuantityKeyOrUomKeyByCountryAndDivision(country, division);
};
export const getUomKeyByCountryAndDivision = (country, division) => {
  return getQuantityKeyOrUomKeyByCountryAndDivision(country, division, false);
};
